.modal-header-layout {
  padding: 0 15px 15px 15px;
  border-radius: 0;

  .modal-header-content {
    width: 100%;
    display: flex;
    height: 60px;
    position: relative;

    h4 {
      margin: 0;
      line-height: 60px;
    }

    .responsive-flex-wrapper {
      position: absolute;
      top: 0;
      width: 30px;
      height: 100%;
      right: 0;

      .material-symbols-outlined {
        margin: 0;
      }
    }
  }
}

@media (min-width: 576px) {
    .modal-dialog {
        width: fit-content;
    }
}
