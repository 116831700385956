
/*-----Fonts-----*/
/* @font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Regular.woff2") format('woff2'), url("../fonts/Proxima_Nova_Regular.woff") format('woff'), url("../fonts/Proxima_Nova_Regular.ttf") format('truetype'); font-style: normal; font-weight: 400; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Regular_Italic.woff2") format('woff2'), url("../fonts/Proxima_Nova_Regular_Italic.woff") format('woff'), url("../fonts/Proxima_Nova_Regular_Italic.ttf") format('truetype'); font-style: italic; font-weight: 400; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Bold.woff2") format('woff2'), url("../fonts/Proxima_Nova_Bold.woff") format('woff'), url("../fonts/Proxima_Nova_Bold.ttf") format('truetype'); font-style: normal; font-weight: 700; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Bold_Italic.woff2") format('woff2'), url("../fonts/Proxima_Nova_Bold_Italic.woff") format('woff'), url("../fonts/Proxima_Nova_Bold_Italic.ttf") format('truetype'); font-style: italic; font-weight: 700; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Semibold.woff2") format('woff2'), url("../fonts/Proxima_Nova_Semibold.woff") format('woff'), url("../fonts/Proxima_Nova_Semibold.ttf") format('truetype'); font-style: normal; font-weight: 600; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Semibold_Italic.woff2") format('woff2'), url("../fonts/Proxima_Nova_Semibold_Italic.woff") format('woff'), url("../fonts/Proxima_Nova_Semibold_Italic.ttf") format('truetype'); font-style: italic; font-weight: 600; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Light.woff2") format('woff2'), url("../fonts/Proxima_Nova_Light.woff") format('woff'), url("../fonts/Proxima_Nova_Light.ttf") format('truetype'); font-style: normal; font-weight: 300; }
@font-face { font-family: 'Plus Jakarta Sans'; src: url("../fonts/Proxima_Nova_Light_Italic.woff2") format('woff2'), url("../fonts/Proxima_Nova_Light_Italic.woff") format('woff'), url("../fonts/Proxima_Nova_Light_Italic.ttf") format('truetype'); font-style: italic; font-weight: 300; } */

html, body, *, input, select, button, textarea, h1, h2, h3 { text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; -moz-font-smoothing: antialiased; -o-font-smoothing: antialiased; }
html, body, * { padding: 0; margin: 0; background: transparent; background-color: transparent; outline: none !important; }
html, body { background: #eef1f5; height: 100%; margin: 0; }
html, body, button, input, select, textarea, h1, h2, h3, p, * { font-family: 'Plus Jakarta Sans', sans-serif; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; -moz-font-smoothing: antialiased; -o-font-smoothing: antialiased; }
html, body, button, input, select, textarea { color: #343434; }

/*-------Headers-------*/
h5 {
  border-bottom: 1px solid #DDD;
  padding-bottom: 15px;
}

/*-------Bootstrap Extra------*/
.m-0 { margin: 0; }
.m-t-30 { margin-top: 30px }
.m-t-20 { margin-top: 20px }
.m-t-100 { margin-top: 100px!important; }
.m-t-70 { margin-top: 70px !important; }
.m-t-8 { margin-top: 8px }
.m-t-5 { margin-top: 5px }
.m-t-3 { margin-top: 3px }
.m-l-5 { margin-left: 5px }
.m-l-8 { margin-left: 8px !important; }
.m-l-10 { margin-left: 10px }
.m-l-20 { margin-left: 20px }
.m-l-30 {margin-left: 30px; }
.m-b-30 { margin-bottom: 30px }
.m-b-5 { margin-bottom: 5px }
.p-b-0 { padding-bottom: 0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-b-8 { margin-bottom: 8px !important; }
.m-r-5 { margin-right: 5px !important; }
.m-r-8 { margin-right: 8px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r { margin-right: 8px; }
.theme-dark-blue .bs-datepicker-head { background-color: var(--wt-primary)  }
.no-wrap { white-space: nowrap; }
.p-8 { padding: 8px; }
.p-5 { padding: 5px; }
.p-2 { padding: 2px; }
.b-0 { border: 0; }
.p-0 { padding: 0 !important; }
.m-a { margin: 15px; }
.m-30 { margin: 30px; }
.p-32 { padding: 32px; }
.w-30 { width: 30% !important; }
.w-25 { width: 25% !important; }
.w-75 { width: 75% !important; }
.mb-32 {
  margin-bottom: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}



.error-message { color: var(--wt-red); }


/*-------Links & Buttons -------*/
a { color: #343434; text-decoration: none !important; }
a.link-blue { cursor: pointer!important; color: var(--wt-primary)!important;  }
a:hover, a:focus { color: var(--wt-primary); }
a:hover, a:focus { color: var(--wt-primary); }
.btn-link { padding: 0; line-height: 21px; margin: 0 0 3px; border: 0; }
.btn { border-radius: 0; color: #FFF; font-weight: 600; border-radius: 7px; border-color: transparent; }
.btn.btn-primary { color: #FFF; background-color: var(--wt-primary); border-color:var(--wt-primary); font-weight: 600; border-radius: 5px; border-color: transparent; }
.btn.btn-rounded { color: #FFF; -webkit-border-radius: 25rem; border-radius: 25rem; }
.btn.btn-secondary { color: #FFF; background-color: #99a2ab; border-color: transparent; border-color: transparent; }
.btn.btn-success { color: #FFF; background-color: #32d199; border-color: transparent; }
.btn.btn-light-blue { color: #ffffff !important; background-color: var(--wt-primary)  !important }
.btn.btn-white { color: #424647 !important; box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08); background-color: white; }
.btn.btn-red { color: #ffffff !important; background-color: var(--wt-red)  !important }
.a-blue { color: var(--wt-primary); transition: all ease-in-out 0.25s; }
.a-blue:hover { color: #999; }
.btn:hover { color: #FFF; opacity: 0.65; }
.btn { border-radius: 0; color: #FFF; font-weight: 600; border-radius: 7px; border-color: transparent; }
.btn.btn-primary { color: #FFF; background-color: var(--wt-primary); border-color:var(--wt-primary); font-weight: 600; border-radius: 5px; border-color: transparent; }
.btn.btn-white { color: #424647 !important; box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08); background-color: white; }
.btn:hover { color: #FFF; opacity: 0.65; }

.small-btn {
  width: 100px;
  height: 32px;
  font-size: 12px;
}

.medium-btn {
  height: 32px;
  width: 68px;
  font-size: 14px;
}

.large-btn {
  width: 128px;
  height: 40px;
  font-size: 16px;
}

.wide-btn {
  width: 218px;
}

.idle-btn {
  color: #424647 !important;
  background-color: #ffffff !important;
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
}

.idle-btn:not(:disabled):not(.disabled):hover {
  color: #424647 !important;
  background-color: #ebf7fa !important;
  background-image: linear-gradient(#ebf7fa, #ebf7fa), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
}

.idle-btn:disabled, .idle-btn.disabled {
  border: solid 1px #ebebeb !important;
  background-color: #ffffff !important;
  color: #e0e0e0 !important;
}

.action-btn {
  color: #ffffff !important;
  background-color: var(--wt-primary) !important;
  background-image: linear-gradient(var(--wt-primary), var(--wt-primary)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
}

.action-btn:not(:disabled):not(.disabled):hover {
  background-color: var(--wt-primary) !important;
  background-image: linear-gradient(var(--wt-primary), var(--wt-primary)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
}

.action-btn:disabled, .action-btn.disabled {
  background-color: #ebebeb !important;
  background-image: linear-gradient(#ebebeb, #ebebeb), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
}

.danger-btn {
  color: #ffffff !important;
  background-color: var(--wt-red) !important;
  background-image: linear-gradient(var(--wt-red), var(--wt-red)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
}

.danger-btn:not(:disabled):not(.disabled):hover {
  background-color: var(--wt-red) !important;
  background-image: linear-gradient(var(--wt-red), var(--wt-red)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
}

.danger-btn:disabled, .danger-btn.disabled {
  background-color: #ebebeb !important;
  background-image: linear-gradient(#ebebeb, #ebebeb), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
}


.btn:not(.btn-white):not(.custom-btn):not(.idle-btn):focus {
  box-shadow: none !important;
}

.filter-button {
  padding: 4px 8px;
  font-weight: 400;
  border-radius: 4px !important;
  font-size: 14px;
  line-height: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.filter-button:not(:disabled):not(.disabled):not(.btn-primary):not(.btn-red):not(.action-btn):not(.danger-btn):hover, .filter-button:not(:disabled):not(.disabled):not(.btn-primary):not(.action-btn):not(.danger-btn).toggled {
  color: #424647 !important;
  background-color: #ebf7fa !important;
  background-image: linear-gradient(#ebf7fa, #ebf7fa), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) !important;
  opacity: 0.75;
}


/*-- Floating -- */
.float-right { float: right; }
.float-left { float: left; }


/*-------Containers ----------*/
.card { margin-bottom: 24px; border-radius: 2px !important; -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.05); -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.05); box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.05); }
.card-body, .card-block { flex: 1 1 auto; padding: 30px 36px; }

/*-----Tables------*/
.mfbootstrappaginator { display: block; }


/*--------Avatars-----------*/
.img-avatar { border-radius: 50%; border: 1px solid #DDD; }
.avatar-preview { display: inline-block; vertical-align: middle; }
.avatar-preview img { display: block; width: 100%; height: 100%; -webkit-border-radius: 50%; border-radius: 50%; }
.avatar-preview.avatar-preview-24 { width: 24px; height: 24px; }
.avatar-preview.avatar-preview-32 { width: 32px; height: 32px; }
.avatar-preview.avatar-preview-48 { width: 48px; height: 48px; }
.avatar-preview.avatar-preview-64 { width: 64px; height: 64px; }
.avatar-preview.avatar-preview-100 { width: 100px; height: 100px; }
.avatar-preview.avatar-preview-128 { width: 128px; height: 128px; }

/* Employee Avatar */
.avatar-initials-circle { position: relative; display: block; background-color: transparent; color: #ffffff; text-align: center; cursor: default; }
.avatar-initials-circle:after { display: block; padding-bottom: 100%; width: 100%; height: 0; border-radius: 50%; background-color: #c2c2c2; content: ""; }
.avatar-initials-circle__inner { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; }
.avatar-initials-circle__wrapper { display: table; width: 100%; height: 100%; }
.avatar-initials-circle__content { display: table-cell; vertical-align: middle; line-height: 35px; }


/*---------RAG------------*/
.rag-box { width: 32px; height: 32px; display: inline-block; border-radius: 4px; padding: 0; }
.rag-box-0 { background-color: var(--rag-grey) }
.rag-box-1 { background-color: var(--rag-red) }
.rag-box-2 { background-color: var(--rag-amber) }
.rag-box-3 { background-color: var(--rag-green) }
.rag-box-4 { background-color: var(--rag-blue); border: none !important; text-align: center; }
.rag-box-4 i {
    font-weight: bold;
    color: #fff;
}

.grab-box-1 { background-color: var(--wt-grey-mid) }
.grab-box-2 { background-color: var(--wt-red) }
.grab-box-3 { background-color: var(--wt-blue-dark) }
.grab-box-4 { background-color: var(--wt-light-orange) }



/* Blue Check box */
/* The container */
.check-container { display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.check-container input { position: absolute; opacity: 0; cursor: pointer; }
.checkmark { position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #eee; border: 1px solid #939899; border-radius: 5px; }
.check-container input:checked ~ .checkmark { background-color: var(--wt-primary); border: 2px solid var(--wt-primary) }
.checkmark:after { content: ""; position: absolute; display: none; }
.check-container input:checked ~ .checkmark:after { display: block; }
.check-container .checkmark:after { left: 6px; top: 0px; width: 5px; height: 14px; border: 1px solid #fff; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }


/*----------------Default Dragula Events---------------*/

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}


.responsive-flex-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.custom-input {
    color: var(--wt-black) !important;
    background-color: var(--wt-blue-extra-pale);
    height: 44px;
    width: 100%;
    border: none;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--wt-grey-dark) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.custom-input:focus {
    color: var(--wt-black) !important;
    box-shadow: unset;
    border-color: #ced4da;
    border-bottom: 2px solid var(--wt-primary) !important;
    background-color: var(--wt-neutral-extra-light);
}

.custom-input:invalid:not(.ng-untouched).required {
  color: red;
  border-bottom:2px solid red !important;
}


.cool-input-wrapper {
    position: relative;
    width: 100%;
}

.cool-input-wrapper input {
    padding-left: 12px !important;
    padding-top:10px;
}

.cool-input-wrapper textarea {
    padding: 13px 48px 10px 10px;
    font-size: 16px;
    line-height: 20px;
}

.cool-input-wrapper {
    margin-bottom: 8px;
}

.cool-input-wrapper .countdown {
    position: absolute;
    color: #ababab;
    right: 4px;
    top: 6px;
    font-size: 14px;
    line-height: 14px;
    width: auto;
    height: auto;
    display: none;
}

.cool-input-wrapper label {
    color: #939899;
    position: absolute;
    top: 15px;
    pointer-events: none;
    left: 10px;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    transition: .2s;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 999;
    width: calc(100% - 60px);
}



.cool-input-wrapper input:invalid:not(.ng-untouched).required ~label {
    color: red;
}

.cool-input-wrapper .ng-valid ~label, .cool-input-wrapper .valid ~label {
    top: 4px;
    left: 10px;
    color: #939899;
    font-size: 12px;
    line-height: 12px;
    display: block !important;
}

.cool-input-wrapper input:focus ~label, textarea:focus ~label {
    color: var(--wt-primary);
    top: 4px;
    left: 10px;
    font-size: 12px;
    line-height: 12px;
    display: block !important;
}

.cool-input-wrapper input.input-placeholder-shown ~label, textarea.input-placeholder-shown ~label {
    top: 4px;
    left: 10px;
    font-size: 12px;
    line-height: 12px;
    display: block !important;
}

.cool-input-wrapper input:focus {
    border-bottom: 2px solid var(--wt-primary);
}

.cool-input-wrapper input:focus ~.countdown {
    display: block !important;
}

.cool-input-wrapper textarea:focus ~.countdown {
    display: block !important;
}

.cool-input-wrapper input ~.countdown-icon {
    display: block !important;
    top:10px;
}


.cool-input-wrapper input.ng-untouched ~.validation-message {
    display: none !important;
}

.cool-input-wrapper input.ng-valid ~.validation-message {
    display: none !important;
}



.form-control:focus {
    box-shadow: none !important;
}

/*--------------- GANTT ----------------- */

.gantt-confirm-modal {
    margin-bottom: 30px;
}


.gantt-confirm-modal .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
}

.gantt-confirm-modal .separator {
    font-size: 14px;
    line-height: 14px;
    margin: 10px 0;
}

.gantt-confirm-modal .description {
    font-size: 14px;
    line-height: 14px;
    margin: 6px 0;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert {
    width: 400px;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text {
    margin: 0;
    padding: 0 !important;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text .gantt-double-click-modal .header {
    font-size: 20px;
    line-height: 20px;
    margin: 10px 0 16px 0;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text .gantt-double-click-modal .body {
    margin: 10px;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text .gantt-double-click-modal .body .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text .gantt-double-click-modal .body .description {
    font-size: 16px;
    line-height: 16px;
    margin: 6px 0px;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_text.dhtmlx_popup_text .gantt-double-click-modal .body .separator {
    font-size: 16px;
    line-height: 16px;
    margin: 14px 0;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_controls.dhtmlx_popup_controls {
    margin: 10px !important;
    padding: 0 !important;
    position: relative;
    height: 32px;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_controls.dhtmlx_popup_controls .gantt_popup_button.dhtmlx_popup_button {
    width: calc(50% - 12px - 2px);
    margin: 0 6px;
    position: absolute;
    height: unset;
    box-shadow: none !important;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_controls.dhtmlx_popup_controls .gantt_popup_button.dhtmlx_popup_button:first-child {
    right: 0;
    top: 0;
    background: var(--wt-primary);
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_controls.dhtmlx_popup_controls .gantt_popup_button.dhtmlx_popup_button:last-child {
    left: 0;
    top: 0;
}

.gantt_modal_box.dhtmlx_modal_box.gantt-alert.dhtmlx-alert .gantt_popup_controls.dhtmlx_popup_controls .gantt_popup_button.dhtmlx_popup_button > div {
    line-height: 32px;
}

perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y, perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x {
    background-color: unset !important;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: unset !important;
}

perfect-scrollbar .ps-content {
    display: flex;
    flex-direction: column;
}

perfect-scrollbar > .ps.ps--scrolling-y > .ps__rail-y, perfect-scrollbar > .ps.ps--scrolling-x > .ps__rail-x {
    background-color: unset !important;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: unset !important;
}

perfect-scrollbar .ps-content {
    display: flex;
    flex-direction: column;
}



/* Pusling Marker ------------------------------------------------------------------------------------------------------------------------------ */

.pulsing-marker {
    width: 32px;
    height: 32px;
    background-color: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pulsing-marker::before, .pulsing-marker::after {
    content: "";
    display: block;
    border: 1px solid red;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 10;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    opacity: 0;
  }

  .pulsing-marker::before {
    -webkit-animation: 1.5s .1s inner-pulse infinite linear;
    -moz-animation: 1.5s .1s inner-pulse infinite linear;
    -o-animation: 1.5s .1s inner-pulse infinite linear;
    animation: 1.5s .1s inner-pulse infinite linear;
  }

  .pulsing-marker::after {
    -webkit-animation: 1.5s pulse2 infinite linear;
    -moz-animation: 1.5s pulse2 infinite linear;
    -o-animation: 1.5s pulse2 infinite linear;
    animation: 1.5s pulse2 infinite linear;
  }

  .pulsing-marker.severity_1::before, .pulsing-marker.severity_1::after {
    border-color: var(--wt-green) !important;
  }
  .pulsing-marker.severity_2::before, .pulsing-marker.severity_2::after {
    border-color: var(--wt-yellow) !important;
  }
  .pulsing-marker.severity_3::before, .pulsing-marker.severity_3::after {
    border-color: var(--wt-light-orange) !important;
  }
  .pulsing-marker.severity_4::before, .pulsing-marker.severity_4::after {
    border-color: var(--wt-orange) !important;
  }
  .pulsing-marker.severity_5::before, .pulsing-marker.severity_5::after {
    border-color: var(--wt-red) !important;
  }

  .pulsing-marker .severity {
    position: absolute;
    transform: translate(0, -4px);
    color: white;
    font-size: 14px;
  }

  @-webkit-keyframes pulse2 {
    0% {
        animation-timing-function:ease-in;
        transform:scale(1)
    }
    50% {
        transform:scale(1.5);
        opacity:.4
    }
    100% {
        animation-timing-function:ease-in-out;
        transform:scale(2.0);
        opacity:0
    }
   }

   @-webkit-keyframes inner-pulse {
    0% {
        animation-timing-function:ease-in;
        transform:scale(1.5)
    }
    50% {
        transform:scale(2.5);
        opacity:.2
    }
    100% {
        animation-timing-function:ease-in-out;
        transform:scale(4.0);
        opacity:0
    }
   }


  @keyframes pulse2 {
    0% {
        animation-timing-function:ease-in;
        transform:scale(1)
    }
    50% {
        transform:scale(1.5);
        opacity:.4
    }
    100% {
        animation-timing-function:ease-in-out;
        transform:scale(2.0);
        opacity:0
    }
  }

  @keyframes inner-pulse {
    0% {
        animation-timing-function:ease-in;
        transform:scale(1.5)
    }
    50% {
        transform:scale(2.5);
        opacity:.2
    }
    100% {
        animation-timing-function:ease-in-out;
        transform:scale(4.0);
        opacity:0
    }
  }

  /* Pusling Marker ------------------------------------- */


  /* Object Marker ------------------------------------------------------------------------------------------------------------------------------ */

  .object-marker-wrapper {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .object-marker-wrapper .what3words-info-tooltip {
    position: absolute;
    bottom: -40px;
    width: max-content;
    background-color: #3a424b;
    border-radius: 4px;
    padding: 3px;
    font-size: 13px;
  }
  .object-marker-wrapper .what3words-info-tooltip:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 8px;
    margin-left: -8px;
    border-bottom-color: #3a424b;
  }
  .object-marker-wrapper .what3words-info-tooltip.hidden {
    display: none;
  }

  .object-marker-wrapper .what3words-info-tooltip .tripple-incline {
    color: #F87C56;
    font-weight: bold;
  }

  .object-marker-wrapper .what3words-info-tooltip .address {
    color: #fff;
  }


  /* Object Marker ------------------------------------- */


/* Google maps overlay styles */

.map-control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    cursor: pointer;
    margin: 6px;
    text-align: center;
    height: 26px;
    min-width: 26px;
    gap: 4px;
}

.map-control-btn i.material-symbols-outlined {
    font-size: 22px !important;
    color: #565656;
}

.map-control-btn.mobile-btn {
  min-width: 32px;
  height: 32px;
}

.map-control-btn.mobile-btn i.material-symbols-outlined {
  font-size: 24px !important;
    color: #565656;
}

.map-control-btn.btn-label {
  padding: 0 8px;
}

/* Marker icon  */
.indicator-makrer-icon-wrapper {
  border-radius: 50%;
}
.indicator-marker-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  position: relative;
  border: 3px solid transparent;
}
.indicator-marker-icon-container > img {
  width: 24px;
  height: 24px;
}

.indicator-marker-icon-container .marker-arrow {
  position: absolute;
  bottom: -12px;
  width: 0;
	height: 0;
	border-width: 12px 10px 0 10px;
	border-color: #FF0000 transparent transparent transparent;
	border-style: solid;
}

.poly-tooltip {
    position: absolute;
    top:0;
    left:0;
    width: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    font-size: 11px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.poly-shape-inactive {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: rgb(86, 86, 86);
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
}

.poly-shape-inactive::before {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
}


.poly-shape-zones {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: var(--wt-primary);
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
}

.poly-shape-zones-areas {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: var(--wt-primary);
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
}

.poly-shape-zones-areas::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 4px;
    width: 12px;
    height: 12px;
    background-color: #F87C56;
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
}

.pac-container {
  z-index: 9999 !important;
}



.infoBox:before{
    content : " ";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #3a424b;
    position: absolute;
    top: -6px;
    left: calc(50% - 6px);
}


/* What3Words Auto-suggestion dropdown */
.w3w-autosuggest-dropdown {
    position: absolute;
    width: 240px;
    height: auto;
    top: 25px;
    left: 26px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
    z-index: 1;
    display: block;
}

.w3w-autosuggest-dropdown .result-container {
    display: block;
    width: 100%;
    padding: 5px 7px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid rgb(218, 218, 218);
}

.w3w-autosuggest-dropdown .result-container:hover {
    background-color: '#ddd';
}

.w3w-autosuggest-dropdown .result-container .words-wrapper {
    width: 100%;
    padding: 2px;
}

.w3w-autosuggest-dropdown .result-container .additional-info-wrapper {
    display: flex;
    width: 100%;
    padding: 2px;
}

.w3w-autosuggest-dropdown .result-container .words-wrapper .words-prefix {
    color: red;
}

.w3w-autosuggest-dropdown .result-container .words-wrapper .words {
    color: #333;
}

.w3w-autosuggest-dropdown .result-container .additional-info-wrapper .nearest-place {
    margin-left: 5px;
}

.rotate-180 {
    transform: rotate(180deg);
}

.modal-body-margin {
    margin-bottom: 65px;
}

.modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
}

@media (max-width: 768px) {
    .modal-body {
        max-height: unset !important;
    }
}

.dropdown-toggle-left::before {
    margin-right: 6px;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}


.fade:not(.show) {
    display: none !important;
}



/** Reporting specifics **/

.reporting-table-row-hide{
    display: none;
}


.quill-mention-user-container {
      display: flex;
      align-items: center;
      width: 100%;
}

  .quill-mention-user-container img.avatar {
    width:32px;
    height:32px;
    border-radius:50%;
  }

  .quill-mention-user-container span.name {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 8px;
      text-align: left;
  }

  .quill-mention-user-container .highlight {
      color: var(--wt-primary);
  }

  .overflow-visible {
      overflow: visible !important;
  }

body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

/* used in reporting */
.rag-red{
  background-color:var(--wt-red);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-amber{
  background-color:var(--wt-light-orange);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-green{
  background-color:var(--wt-green);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-blue{
  background-color:var(--wt-blue-dark);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-grey{
  background-color:var(--wt-grey);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-yellow{
  background-color:var(--wt-yellow);
  width:28px;
  height:28px;
  border-radius:5px
}

.rag-orange{
  background-color:var(--wt-orange);
  width:28px;
  height:28px;
  border-radius:5px
}


/* Material symbols RESET */
.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24
}
