.no-results-found-layout {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #939899;

  .material-symbols-outlined {
    font-size: 40px;
  }

  .no-results-found-text {
    font-size: 22px;
    margin-left: 8px;
  }
}
